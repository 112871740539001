import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Building, Book, Calendar as CalendarIcon } from "lucide-react";

const Welcome = ({ user, stats }) => {
  const { t } = useTranslation();

  const quickStats = [
    {
      title: t("stats_apartments"),
      value: stats?.apartments || 0,
      icon: Building,
      description: t("stats_apartments_desc"),
    },
    {
      title: t("stats_guides"),
      value: stats?.guides || 0,
      icon: Book,
      description: t("stats_guides_desc"),
    },
    {
      title: t("stats_bookings"),
      value: stats?.bookings || 0,
      icon: CalendarIcon,
      description: t("stats_bookings_desc"),
    },
  ];

  return (
    <div className="space-y-6">
      {/* Welcome Card */}
      <Card className="bg-white">
        <CardHeader>
          <CardTitle className="text-2xl">
            {t("welcome_back", { name: user?.name || t("user") })}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground">
            {t("welcome_message")}
          </p>
        </CardContent>
      </Card>

      {/* Quick Stats */}
      <div className="grid gap-4 md:grid-cols-3">
        {quickStats.map((stat, index) => (
          <Card key={index} className="bg-white">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {stat.title}
              </CardTitle>
              <stat.icon className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stat.value}</div>
              <p className="text-xs text-muted-foreground">
                {stat.description}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Recent Activity */}
      <Card className="bg-white">
        <CardHeader>
          <CardTitle>{t("recent_activity")}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* Qui potresti mostrare le ultime attività */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Welcome;