//src/components/CheckinEngine.js

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Calendar } from "./ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import Menu from "./Menu";
import { useTranslation } from "react-i18next";

const CheckinEngine = ({ apartmentTitle, reservationAlias }) => {
  const [checkInOpen, setCheckInOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [error, setError] = useState("");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.body.classList.remove("is-preload");
    window.ontouchmove = () => false;
    window.onorientationchange = () => (document.body.scrollTop = 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDate) {
      setError("Please select a check-in date.");
      return;
    }
    setRedirectUrl("");

    const bookingData = {
      day: selectedDate.getDate(),
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    };

    setLoading(true);

    try {
      const response = await fetch(`/api/booking_date/${reservationAlias}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bookingData),
      });

      const responseData = await response.json();
      console.log(responseData);

      if (response.ok) {
        window.location.href = responseData.redirectTo;
      } else {
        setError(responseData.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setCheckInOpen(false); // Chiude il popover dopo la selezione
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 p-8">
      <Menu />
      <header className="mb-12 text-center pt-10">
        <h1 className="text-4xl font-bold text-blue-800 mb-2">
          {apartmentTitle}
        </h1>
        <p className="text-xl text-gray-600">
          Please select your Check-In date below
        </p>
      </header>

      <main className="max-w-8xl mx-auto">
        <Card className="mb-20 shadow-lg bg-white">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold text-center">
              Check-In Date
            </CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="flex flex-col items-center">
                <Popover open={checkInOpen} onOpenChange={setCheckInOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={`w-full h-12 flex items-center justify-between pl-3 text-left font-normal bg-white text-base ${
                        !selectedDate ? "text-muted-foreground" : ""
                      }`}
                    >
                      <span>
                        {selectedDate
                          ? format(selectedDate, "d MMMM yyyy", { locale: it })
                          : "Select date"}
                      </span>
                      <CalendarIcon className="ml-2 h-4 w-4" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 bg-white">
                    <Calendar
                      mode="single"
                      selected={selectedDate}
                      onSelect={handleDateSelect}
                      disabled={(date) => date < new Date()} // Disabilita le date precedenti a oggi
                      initialFocus
                      locale={it}
                    />
                  </PopoverContent>
                </Popover>
              </div>

              <Button
                type="submit"
                className="w-full h-12 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-base"
                disabled={loading || !selectedDate}
              >
                {loading ? "Please wait..." : "Search"}
              </Button>

              {error && (
                <p className="text-red-500 text-center font-semibold">
                  {error}
                </p>
              )}
            </form>
          </CardContent>
        </Card>
      </main>

      <footer className="mt-12 text-center text-gray-600">
        <p>&copy; 2024. Designed by Simone Ruggiero</p>
      </footer>

      {redirectUrl && (
        <a href={redirectUrl} style={{ display: "none" }}>
          Click here to go to the booking page
        </a>
      )}
    </div>
  );
};

export default CheckinEngine;
