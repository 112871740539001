// components/dashboard/GuideList.js
import React from "react";
import { useTranslation } from "react-i18next";
import ItemList from "./ItemList";

const GuideList = ({ guides, isLoading, error, onRefetch }) => {
  const { t } = useTranslation();

  return (
    <ItemList
      items={guides}
      isLoading={isLoading}
      error={error}
      onRefetch={onRefetch}
      itemType="guides"
      title={t("guides_title")}
      addNewLabel={t("guides_add_new")}
    />
  );
};

export default GuideList;
