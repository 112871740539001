//client/src/components/ApartmentDetail.js

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import {
  MapPin,
  Bed,
  Users,
  Home,
  Euro,
  Utensils,
  Tv,
  Wifi,
  Coffee,
} from "lucide-react";
import Menu from "./Menu";
import NotFound from "../components/NotFound";

import { UPLOADS_URL } from "../config";
import { API_BASE_URL } from "../config";

const ApartmentDetail = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [apartment, setApartment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApartment = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/apartments/public/${id}`);
        setApartment(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching apartment:", err);
        setError(`${t("apartment.fetchError")}: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchApartment();
  }, [id, t]);

  const getTranslatedContent = (content) => {
    const currentLanguage = i18n.language;
    return content.translations?.[currentLanguage] || content;
  };

  if (isLoading) {
    return <div className="text-center py-12">{t("apartment.loading")}</div>;
  }

  if (error || !apartment) {
    return <NotFound />; // Mostra la pagina NotFound se c'è un errore o l'appartamento non viene trovato
  }

  if (!apartment) {
    return <div className="text-center py-12">{t("apartment.notFound")}</div>;
  }

  const translatedApartment = getTranslatedContent(apartment);

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 p-4 sm:p-8 ">
      <Menu />
      <Card className="w-full overflow-hidden shadow-lg mb-8">
        <header className="mb-10 text-center pt-20">
          <CardHeader className="p-6 bg-primary text-white">
            <div className="flex justify-between items-start">
              <div>
                <CardTitle className="text-3xl font-bold mb-2">
                  {translatedApartment.title}
                </CardTitle>
                <div className="flex items-center text-sm">
                  <MapPin size={16} className="mr-1" />
                  <span>
                    {apartment.location || t("apartment.locationNotAvailable")}
                  </span>
                </div>
              </div>
            </div>
          </CardHeader>
        </header>

        <CardContent className="p-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            <div className="flex items-center p-3 bg-gray-100 rounded-lg">
              <Users size={24} className="mr-2 text-primary" />
              <span>
                {t("apartment.guests", { count: apartment.max_guests })}
              </span>
            </div>
            <div className="flex items-center p-3 bg-gray-100 rounded-lg">
              <Home size={24} className="mr-2 text-primary" />
              <span>
                {apartment.size
                  ? `${apartment.size} m²`
                  : t("apartment.sizeNotAvailable")}
              </span>
            </div>
            {apartment.price && (
              <div className="flex items-center p-3 bg-gray-100 rounded-lg">
                <Euro size={24} className="mr-2 text-primary" />
                <span>{t("apartment.price", { price: apartment.price })}</span>
              </div>
            )}
          </div>

          <Tabs defaultValue="overview" className="w-full">
            <TabsList className="mb-4">
              <TabsTrigger value="overview">
                {t("apartment.overview")}
              </TabsTrigger>
              <TabsTrigger value="amenities">
                {t("apartment.amenities")}
              </TabsTrigger>
            </TabsList>

            <TabsContent value="overview">
              <p className="text-gray-700 mb-6">
                {translatedApartment.description}
              </p>
              {apartment.photos && apartment.photos.length > 0 && (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                  {apartment.photos.map((photo, index) => (
                    <img
                      key={index}
                      src={photo.url}
                      alt={`Apartment view ${index + 1}`}
                      className="w-full h-48 object-cover rounded-lg"
                    />
                  ))}
                </div>
              )}
            </TabsContent>

            <TabsContent value="amenities">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {apartment.services &&
                  apartment.services.map((service, index) => (
                    <div
                      key={index}
                      className="flex items-center p-3 bg-gray-100 rounded-lg"
                    >
                      <span className="ml-2">{service}</span>
                    </div>
                  ))}
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};

export default ApartmentDetail;
