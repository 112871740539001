//client/src/components/ApartmentCard.js
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Badge } from "./ui/badge";
import { Bed, Users, MapPin, Euro } from "lucide-react";
import ImageCarousel from "./ImageCarousel";

const ApartmentCard = ({ apartment }) => {
  const { t } = useTranslation();

  if (!apartment) {
    return null;
  }

  return (
    <Link to={`/apartments/${apartment.id}`}>
      <Card className="w-full overflow-hidden transition-all duration-300 hover:shadow-lg">
        <CardHeader className="p-0 relative">
         
        </CardHeader>
        <CardContent className="p-6">
          <div className="flex justify-between items-start mb-4">
            <div>
               <ImageCarousel images={apartment.photos || []} />
              <CardTitle className="text-2xl font-bold mb-2 text-gray-900">
                {apartment.title || t("apartment.noTitle")}
              </CardTitle>
              {apartment.location && (
                <div className="flex items-center text-sm text-gray-500 mb-2">
                  <MapPin size={16} className="mr-1" />
                  <span>{apartment.location}</span>
                </div>
              )}
            </div>
            {apartment.type && (
              <Badge variant="secondary" className="text-sm">
                {apartment.type}
              </Badge>
            )}
          </div>
          <CardDescription className="text-base mb-4 text-gray-700">
            {apartment.description || t("apartment.noDescription")}
          </CardDescription>
          <div className="grid grid-cols-3 gap-4 mb-4 text-base mb-4 text-gray-700">
            {apartment.beds !== undefined && (
              <div className="flex items-center">
                <Bed size={20} className="mr-2 text-primary" />
                <span>{t("apartment.beds", { count: apartment.beds })}</span>
              </div>
            )}
            {apartment.guests !== undefined && (
              <div className="flex items-center">
                <Users size={20} className="mr-2 text-primary" />
                <span>
                  {t("apartment.guests", { count: apartment.guests })}
                </span>
              </div>
            )}
            {apartment.price !== undefined && (
              <div className="flex items-center">
                <Euro size={20} className="mr-2 text-primary" />
                <span>{t("apartment.price", { price: apartment.price })}</span>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};

export default ApartmentCard;
