import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./static/css/ImageCarousel.css";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    if (images && images.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const prevImage = () => {
    if (images && images.length > 0) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length,
      );
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextImage,
    onSwipedRight: prevImage,
  });

  if (!images || images.length === 0) {
    return (
      <div className="carousel-container" {...handlers}>
        <img
          src="/placeholder-image.jpg"
          alt="Placeholder"
          className="carousel-image"
        />
      </div>
    );
  }

  return (
    <div className="carousel-container" {...handlers}>
      <img
        src={images[currentIndex]["url"]}
        alt={`Apartment ${currentIndex + 1}`}
        className="carousel-image"
      />
      {images.length > 1 && (
        <>
          <button
            className="carousel-button carousel-button-prev"
            onClick={prevImage}
          >
            &lt;
          </button>
          <button
            className="carousel-button carousel-button-next"
            onClick={nextImage}
          >
            &gt;
          </button>
        </>
      )}
    </div>
  );
};

export default ImageCarousel;
