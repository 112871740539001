// components/dashboard/ItemCard.js
import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Button } from "../ui/button";
import { Trash2 } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { useTranslation } from "react-i18next";

const ItemCard = ({ item, onEdit, onDelete, itemType }) => {
  const { t } = useTranslation();

  return (
    <Card className="relative">
      <CardHeader>
        <CardTitle>{item.translations?.it?.title || item.title}</CardTitle>
      </CardHeader>
      <CardContent>
        <p>{item.translations?.it?.description || item.description}</p>
        {itemType === "apartments" && (
          <div className="mt-2">
            <p>
              {t("dashboard_item_card_max_guests", { count: item.max_guests })}
            </p>
            <p>
              {t("dashboard_item_card_services", {
                services: Array.isArray(item.services)
                  ? item.services.join(", ")
                  : t("dashboard_item_card_no_services"),
              })}
            </p>
            <p>
              {t("dashboard_item_card_location", {
                location:
                  item.location ||
                  t("dashboard_item_card_location_not_specified"),
              })}
            </p>
          </div>
        )}
        {item.photos && item.photos.length > 0 && (
          <img
            src={item.photos[0].url}
            alt={`Prima foto di ${item.translations?.it?.title || item.title}`}
            className="w-full h-32 object-cover mt-2 rounded"
          />
        )}
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button onClick={onEdit}>{t("common_edit")}</Button>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="destructive">
              <Trash2 className="h-4 w-4" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50">
            <AlertDialogHeader>
              <AlertDialogTitle>
                {t("dashboard_item_card_delete_confirm_title")}
              </AlertDialogTitle>
              <AlertDialogDescription>
                {t("dashboard_item_card_delete_confirm_description", {
                  itemType: t(`${itemType}_title`),
                })}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t("common_cancel")}</AlertDialogCancel>
              <AlertDialogAction onClick={onDelete}>
                {t("common_delete")}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardFooter>
    </Card>
  );
};

export default ItemCard;
