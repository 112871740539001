// components/dashboard/Sidebar.js
import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../lib/utils";
import {
  Home,
  Building,
  Book,
  Calendar as CalendarIcon,
  Settings as SettingsIcon,
  Users,
} from "lucide-react";

const Sidebar = ({ activeSection, setActiveSection, isAdmin, className }) => {
  const { t } = useTranslation();

  const menuItems = [
    { id: "home", label: t("menu_home"), icon: Home },
    { id: "apartments", label: t("menu_apartments"), icon: Building },
    { id: "guides", label: t("menu_guides"), icon: Book },
    { id: "calendar", label: t("menu_calendar"), icon: CalendarIcon },
    { id: "settings", label: t("menu_settings"), icon: SettingsIcon },
    ...(isAdmin
      ? [{ id: "users", label: t("menu_users"), icon: Users }]
      : []),
  ];

  return (
    <nav className={cn("w-64 bg-gray-800 p-4", className)}>
      <ul className="space-y-2">
        {menuItems.map((item) => {
          const Icon = item.icon;
          return (
            <li key={item.id}>
              <button
                onClick={() => setActiveSection(item.id)}
                className={cn(
                  "w-full flex items-center gap-3 px-4 py-2 rounded-lg transition-colors",
                  "text-sm font-medium",
                  activeSection === item.id
                    ? "bg-gray-700 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white"
                )}
              >
                <Icon size={20} />
                {item.label}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
