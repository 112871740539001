import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../hooks/useApi";
import { toast } from "../ui/use-toast";

const Settings = () => {
  const { t } = useTranslation();
  const { user, updateUser } = useAuth();
  const { apiCall } = useApi();

  const [personalData, setPersonalData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    company: user?.company || "",
    vatNumber: user?.vatNumber || "",
    fiscalCode: user?.fiscalCode || "",
    address: user?.address || "",
    city: user?.city || "",
    postalCode: user?.postalCode || "",
    country: user?.country || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonalData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = await apiCall("put", `/users/${user.id}`, personalData);
      updateUser(updatedUser);
      toast({
        title: t("settings_update_success"),
        description: t("settings_update_success_message"),
      });
    } catch (error) {
      toast({
        title: t("settings_update_error"),
        description: t("settings_update_error_message"),
        variant: "destructive",
      });
    }
  };

  return (
    <div className="h-full">
      <Card className="bg-white h-full">
        <CardHeader>
          <CardTitle>{t("settings_personal_info")}</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Informazioni Personali */}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="space-y-2">
                <Label htmlFor="name">{t("settings_name")}</Label>
                <Input
                  id="name"
                  name="name"
                  value={personalData.name}
                  onChange={handleInputChange}
                  className="w-full"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">{t("settings_email")}</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={personalData.email}
                  onChange={handleInputChange}
                  className="w-full"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="phone">{t("settings_phone")}</Label>
                <Input
                  id="phone"
                  name="phone"
                  value={personalData.phone}
                  onChange={handleInputChange}
                  className="w-full"
                />
              </div>
            </div>

            {/* Informazioni di Fatturazione */}
            <div className="pt-6 border-t">
              <h3 className="text-lg font-medium mb-4">{t("settings_billing_info")}</h3>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label htmlFor="company">{t("settings_company")}</Label>
                  <Input
                    id="company"
                    name="company"
                    value={personalData.company}
                    onChange={handleInputChange}
                    className="w-full"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="vatNumber">{t("settings_vat_number")}</Label>
                  <Input
                    id="vatNumber"
                    name="vatNumber"
                    value={personalData.vatNumber}
                    onChange={handleInputChange}
                    className="w-full"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="fiscalCode">{t("settings_fiscal_code")}</Label>
                  <Input
                    id="fiscalCode"
                    name="fiscalCode"
                    value={personalData.fiscalCode}
                    onChange={handleInputChange}
                    className="w-full"
                  />
                </div>
              </div>
            </div>

            {/* Indirizzo */}
            <div className="pt-6 border-t">
              <h3 className="text-lg font-medium mb-4">{t("settings_address")}</h3>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label htmlFor="address">{t("settings_street_address")}</Label>
                  <Input
                    id="address"
                    name="address"
                    value={personalData.address}
                    onChange={handleInputChange}
                    className="w-full"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="city">{t("settings_city")}</Label>
                  <Input
                    id="city"
                    name="city"
                    value={personalData.city}
                    onChange={handleInputChange}
                    className="w-full"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="postalCode">{t("settings_postal_code")}</Label>
                  <Input
                    id="postalCode"
                    name="postalCode"
                    value={personalData.postalCode}
                    onChange={handleInputChange}
                    className="w-full"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="country">{t("settings_country")}</Label>
                  <Input
                    id="country"
                    name="country"
                    value={personalData.country}
                    onChange={handleInputChange}
                    className="w-full"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-6">
              <Button type="submit" className="w-full sm:w-auto">
                {t("settings_save_changes")}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Settings;