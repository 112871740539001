// src/App.js

import React from "react";
import Guides from "./components/Guides";
import "./styles/globals.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import CheckinEngine from "./components/CheckinEngine";
import Apartments from "./components/Apartments";
import Home from "./components/Home";
import { inject } from "@vercel/analytics";
import GuideDetail from "./components/GuideDetail";
import ApartmentDetail from "./components/ApartmentDetail";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Dashboard from "./components/dashboard/Dashboard";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AdminRoute from "./components/auth/AdminRoute";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import AdminUsers from "./components/AdminUsers";
import NotFound from "./components/NotFound";
import Layout from "./components/Layout";
import { GoogleOAuthProvider } from '@react-oauth/google';

inject();

const MainApp = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reservationAlias = searchParams.get("apartment");
  const apartments = JSON.parse(process.env.REACT_APP_MY_APARTMENTS);

  let apartmentTitle = null;

  try {
    if (reservationAlias && apartments.hasOwnProperty(reservationAlias)) {
      apartmentTitle = apartments[reservationAlias];
    } else {
      console.log("Apartment not found");
    }
  } catch (error) {
    console.error(error);
  } 
  
  if (apartmentTitle !== null) {
    return (
      <CheckinEngine
        apartmentTitle={apartmentTitle}
        reservationAlias={reservationAlias}
      />
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/apartments" element={<Apartments />} />
      <Route path="/guides" element={<Guides />} />
      <Route path="/guides/:id" element={<GuideDetail />} />
      <Route path="/apartments/:id" element={<ApartmentDetail />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/users"
        element={
          <AdminRoute>
            <AdminUsers />
          </AdminRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App = () => {
  console.log("Google Client ID:", process.env.REACT_APP_GOOGLE_CLIENT_ID);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <I18nextProvider i18n={i18n}>
          <Router>
            <MainApp />
          </Router>
        </I18nextProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
