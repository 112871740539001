// src/hooks/useDataPrefetch.js
import { useState, useEffect, useCallback } from "react";
import { useApi } from "./useApi";

const cache = new Map();

export const useDataPrefetch = (key, fetchFunction) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { apiCall } = useApi();

  const fetchData = useCallback(async () => {
    if (cache.has(key)) {
      setData(cache.get(key));
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const result = await fetchFunction(apiCall);
      cache.set(key, result);
      setData(result);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [key, fetchFunction, apiCall]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const invalidateCache = useCallback(() => {
    cache.delete(key);
  }, [key]);

  return { data, isLoading, error, refetch: fetchData, invalidateCache };
};

// Funzione di prefetching
export const prefetchData = async (key, fetchFunction, apiCall) => {
  if (!cache.has(key)) {
    try {
      const data = await fetchFunction(apiCall);
      cache.set(key, data);
    } catch (error) {
      console.error(`Error prefetching ${key}:`, error);
    }
  }
};
