// src/components/NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Image } from "lucide-react";

const NotFound = () => {
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      <Card className="w-full max-w-md">
        <CardContent className="flex flex-col items-center pt-6">
          <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-6">
            <Image className="w-8 h-8 text-gray-400" />
          </div>
          <h1 className="text-4xl font-bold mb-2">Oops, page not found!</h1>
          <p className="text-gray-600 text-center mb-6">
            The page you're looking for doesn't exist. Let's get you back on
            track.
          </p>
          <div className="flex space-x-4">
            <Button onClick={goToHomepage} variant="default">
              Go to Homepage
            </Button>
            <Button variant="outline">View Error Code</Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NotFound;
