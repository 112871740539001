import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "./Sidebar";
import ApartmentList from "./ApartmentList";
import GuideList from "./GuideList";
import Calendar from "./Calendar";
import Settings from "./Settings";
import { useApi } from "../../hooks/useApi";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import AdminUsersTable from "../AdminUsersTable";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import Welcome from "./Welcome";
import { Menu, X } from "lucide-react";
import { cn } from "../../lib/utils";

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("home");
  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { apiCall, isLoading: isLoadingApi, error: apiError } = useApi();

  const [apartments, setApartments] = useState([]);
  const [guides, setGuides] = useState([]);
  const [stats, setStats] = useState({
    apartments: 0,
    guides: 0,
    bookings: 0
  });

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const fetchApartments = useCallback(async () => {
    try {
      const data = await apiCall("get", "/apartments");
      console.log("appartamenti da api", data);
      setApartments(data);
    } catch (error) {
      console.error("Error fetching apartments:", error);
    }
  }, [apiCall]);

  const fetchGuides = useCallback(async () => {
    try {
      const data = await apiCall("get", "/guides");
      console.log("guide da api", data);
      setGuides(data);
    } catch (error) {
      console.error("Error fetching guides:", error);
    }
  }, [apiCall]);

  useEffect(() => {
    console.log("Dashboard - Current user:", user);
    fetchApartments();
    fetchGuides();
  }, [fetchApartments, fetchGuides, user]);

  useEffect(() => {
    const totalBookings = apartments.reduce((total, apartment) => {
      return total + (apartment.bookings?.length || 0);
    }, 0);

    setStats({
      apartments: apartments.length,
      guides: guides.length,
      bookings: totalBookings
    });
  }, [apartments, guides]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case "home":
        return <Welcome user={user} stats={stats} />;
      case "apartments":
        return (
          <ApartmentList
            apartments={apartments}
            isLoading={isLoadingApi}
            error={apiError}
            onRefetch={fetchApartments}
          />
        );
      case "guides":
        return (
          <GuideList
            guides={guides}
            isLoading={isLoadingApi}
            error={apiError}
            onRefetch={fetchGuides}
          />
        );
      case "calendar":
        return <Calendar />;
      case "settings":
        return <Settings />;
      case "users":
        return user.role === "admin" ? <AdminUsersTable /> : null;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-screen bg-background">
      <header className="bg-gray-800 text-white p-4 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            className="md:hidden text-white hover:bg-gray-700"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </Button>
          <h1 className="text-xl font-bold">{t("dashboard_title")}</h1>
        </div>
        <Button
          onClick={handleLogout}
          variant="ghost"
          className="text-white hover:bg-gray-700 bg-transparent"
        >
          {t("logout")}
        </Button>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar mobile */}
        <div
          className={cn(
            "fixed inset-0 z-50 bg-black/80 backdrop-blur-sm transition-opacity md:hidden",
            isMobileMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          )}
          onClick={toggleMobileMenu}
        >
          <div
            className={cn(
              "fixed inset-y-0 left-0 w-full max-w-xs bg-gray-800 transition-transform duration-300",
              isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <Sidebar
              activeSection={activeSection}
              setActiveSection={(section) => {
                setActiveSection(section);
                toggleMobileMenu();
              }}
              isAdmin={user?.role === "admin"}
              className="h-full"
            />
          </div>
        </div>

        {/* Sidebar desktop */}
        <div className="hidden md:block h-full bg-gray-800">
          <Sidebar
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            isAdmin={user?.role === "admin"}
            className="h-full"
          />
        </div>

        {/* Main content */}
        <main className="flex-1 overflow-auto p-4 md:p-8">
          <div className="container mx-auto">
            {isLoadingApi && <p className="text-center">{t("loading")}</p>}
            {apiError && (
              <p className="text-red-500 text-center">
                {t("error_message")}: {apiError}
              </p>
            )}
            {!isLoadingApi && !apiError && (
              <div className="space-y-6">
                {renderContent()}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
