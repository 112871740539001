// src/components/GuideDetail.js

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { getGuideById } from "../utils/dataloader"; // Assicurati che questa funzione esista
import axios from "axios";
import { API_BASE_URL } from "../config";

const GuideDetail = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        const fetchedGuide = await axios.get(`${API_BASE_URL}/guides/public/${id}`);
        console.log('guida fecciata',fetchedGuide.data);
        setGuide(fetchedGuide.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching guide:", err);
        setError(t("guide.fetchError"));
        setLoading(false);
      }
    };

    fetchGuide();
  }, [id, i18n.language, t]);

  if (loading) return <div>{t("guide.loading")}</div>;
  if (error) return <div>{error}</div>;
  if (!guide) return <div>{t("guide.notFound")}</div>;

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen">
      <Menu />
      <header className="mb-10 text-center pt-16">
  <h1 className="text-4xl font-bold text-blue-800 mb-2">
    {guide.translations[i18n.language]?.title || guide.title}
  </h1>
  <p className="text-xl text-gray-600">
    {guide.translations[i18n.language]?.description || guide.description}
  </p>
</header>

      <main className="max-w-6xl mx-auto px-4">
        {guide.image && (
          <img
            src={guide.image}
            alt={guide.translations[i18n.language]?.title || guide.title}
            className="w-full h-96 object-cover mb-8 rounded-lg shadow-md"
          />
        )}
        
        <div className="prose prose-lg max-w-none">
          {/* Content Section */}
          <div className="bg-white p-6 rounded-lg shadow-sm mb-8">
            <div className="content-text" 
                 dangerouslySetInnerHTML={{ 
                   __html: guide.translations[i18n.language]?.content || guide.content 
                 }} 
            />
          </div>

          {/* Additional Info Section */}
          {guide.additionalInfo && (
            <div className="bg-blue-50 p-6 rounded-lg mb-8">
              <h2 className="text-2xl font-semibold text-blue-800 mb-4">
                {t("guide.additionalInfo")}
              </h2>
              <div className="text-gray-700">
                {guide.translations[i18n.language]?.additionalInfo || guide.additionalInfo}
              </div>
            </div>
          )}

          {/* Tags Section */}
          {guide.tags && guide.tags.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-medium text-gray-700 mb-2">{t("guide.tags")}</h3>
              <div className="flex flex-wrap gap-2">
                {guide.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>

      <footer className="mt-8 sm:mt-12 text-center text-gray-600 pb-8">
        <p className="text-sm sm:text-base">{t("common_footer")}</p>
      </footer>
    </div>
  );
};

export default GuideDetail;
