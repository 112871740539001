// client/src/contexts/AuthContext.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import authService from "../services/authService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = useCallback(async () => {
    console.log("Checking authentication...");
    setIsLoading(true);
    try {
      const user = await authService.verifyToken();
      console.log("Token verification response:", user);
      setUser(user);
    } catch (error) {
      console.log("No login token");
      authService.logout();
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = async (email, password) => {
    try {
      const response = await authService.login(email, password);
      console.log("Login response:", response);
      setUser(response.user);
      return response;
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = useCallback(() => {
    authService.logout();
    setUser(null);
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const newToken = await authService.refreshToken();
      await checkAuth();
      return newToken;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logout();
      throw error;
    }
  }, [logout, checkAuth]);

  const googleLogin = async (credential) => {
    try {
      const response = await authService.googleLogin(credential);
      console.log("Google login response:", response);
      setUser(response.user);
      return response;
    } catch (error) {
      console.error("Google login failed:", error);
      throw error;
    }
  };

  const authContextValue = {
    user,
    login,
    logout,
    refreshToken,
    isLoading,
    checkAuth,
    googleLogin,
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
